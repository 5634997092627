import React from 'react'
import { graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Center, Heading, Box, useColorMode } from "@chakra-ui/react"
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Prose } from '@nikolovlazar/chakra-ui-prose'

export function Head() {
  return (<>
    <title>JJR</title>
    <meta property="og:title" content="JJR | Contact" />
    <meta property="og:description" content="Jonathan Joseph Rosse Jewelry" />
    <meta property="og:image" content="/images/og_image.jpg" />
  </>)
}

const Page = ({ data }) => {
  const { colorMode } = useColorMode()

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImage, description } = node.data.target
        return (
          <GatsbyImage
            image={getImage(gatsbyImage)}
            alt={description}
          />
        )
      },
    },
  };

  const images = colorMode === "light" ? data.contentfulPiece.lightImages : data.contentfulPiece.darkImages;

  return (
    <Center py="24" flex="auto" minHeight="calc(100vh - 48px)">
      <Box 
        display="flex" 
        flexDirection="column"
        maxWidth="1200px"
        width="100%"
        px="4"
        my="auto"
      >
        {/* Title and Price Section */}
        <Box textAlign="center" mb="4">
          <Heading>{data.contentfulPiece.name}</Heading>
          <Heading size="md" mt="4">
            ${Number(data.contentfulPiece.price).toLocaleString()}
          </Heading>
        </Box>

        {/* Main Content Container */}
        <Box 
          display="flex" 
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
          gap="8"
        >
          {/* Images Section */}
          <Box flex="1">
            {images && images.map((image, index) => (
              <Box key={index} width="100%" mb={index < images.length - 1 ? "4" : "0"}>
                <img 
                  src={image.resize.src} 
                  alt={data.contentfulPiece.name}
                  style={{ width: '100%', height: 'auto' }}
                />
              </Box>
            ))}
          </Box>

          {/* Content Section */}
          <Box flex="1">
            <Prose>
              {data.contentfulPiece.description?.raw && renderRichText(data.contentfulPiece.description, options)}
            </Prose>
          </Box>
        </Box>
      </Box>
    </Center>
  )
}

export default Page

export const data = graphql`
  query piecePageQuery($slug: String) {
    contentfulPiece(slug: { eq: $slug }) {
      name
      price
      description {
        raw
      }
      lightImages {
        resize(width: 1400, quality: 100) {
          src
        }
      }
      darkImages {
        resize(width: 1400, quality: 100) {
          src
        }
      }
    }
  }
`;
